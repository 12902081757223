import vue from "vue";
import {
  Toast,
  Icon,
  List,
  Form,
  Button,
  Field,
  Tab,
  Tabs,
  NavBar,
  Col,
  ImagePreview,
  Row,
  Dialog,
  Popup,
  Loading,
  RadioGroup,
  Radio,
  Overlay,
  Divider,
  Uploader,
  PullRefresh,
  Badge,
  Empty,
  Popover,
  Search,
  ShareSheet,
  Image as VanImage,
} from "vant";

vue
  .use(Toast)
  .use(Loading)
  .use(Icon)
  .use(ShareSheet)
  .use(List)
  .use(Badge)
  .use(Search)
  .use(Empty)
  .use(Form)
  .use(Button)
  .use(Field)
  .use(Dialog)
  .use(ImagePreview)
  .use(VanImage)
  .use(Tab)
  .use(Divider)
  .use(Tabs)
  .use(Overlay)
  .use(NavBar)
  .use(Col)
  .use(Row)
  .use(Radio)
  .use(RadioGroup)
  .use(Popup)
  .use(Uploader)
  .use(NavBar)
  .use(PullRefresh)
  .use(Popover);
