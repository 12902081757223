import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@assets/css/index.scss";
import "@plugin/vant.js";
import "@utils/isIphoneX";

//引入touch组件
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })
VueTouch.config.swipe = {
  threshold: 100 //手指左右滑动距离
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created () {
    // 判单当前的登录设备
    if (window.webkit) {
      // 如果是ios就调用iOS定义的全局方法
      console.log("devive is ios");
    } else if (window.android) {
      // Android
      const userInfo = window.android.getUserToken();
      // 如果传递的用户信息是空就不存缓存
      let userToken = JSON.parse(userInfo)["token"];
      if (userToken) {
        // 获取到的信息 存储到localStorage中
        window.localStorage.setItem(
          "userInfo",
          JSON.stringify(JSON.parse(userInfo))
        );
      }
    } else {
      console.log("this is pc please switch Mobile browser");
    }
    // 当应用创建的时候 判断用户用户是否登录 通过浏览器的localStorage看是否可以获取到用户的信息
    const user = window.localStorage.getItem("userInfo");

    // console.log(user)
    const profile = window.localStorage.getItem("profile");
    // const whiteList = ['/vipbuy','/login']
    if (profile) {
      this.$store.commit("SAVE_PROFILE", JSON.parse(profile));
    }

    if (user) {
      this.$store.commit("Login_succ", JSON.parse(user));
      this.$store.dispatch("GET_PROFILE");
    } else {

    }
  },
  render: (h) => h(App),
}).$mount("#app");
