import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/home",
  },
  // 首页
  {
    path: "/home",
    name: "home",
    component: () => import("@views/Home.vue"),
    children: [
      {
        path: "/home",
        redirect: "/home/InformationBase",
      },
      {
        path: "InformationBase",
        name: "Informationbase",
        component: () => import("@components/InformationBase"),
      },
      {
        path: "InformationBase2",
        name: "Informationbase2",
        component: () => import("@components/InformationBase"),
      },
      {
        path: "Network",
        name: "Network",
        component: () => import("@components/Network"),
      },
      {
        path: "ServiceMall",
        name: "ServiceMall",
        component: () => import("@components/ServiceMall"),
        children:[
          {
            path: "",
            name: "",
            component: () => import("@components/ServiceMalladmin"),
          },
          {
            path: "ServiceMalladmin",
            name: "ServiceMalladmin",
            component: () => import("@components/ServiceMalladmin"),
          },
          {
            path: "shoppingcar",
            name: "shoppingcar",
            component: () => import("@components/shoppingcar"),
          },
        ]
      },
      {
        path: "Community2",
        name: "Community2",
        component: () => import("@components/Community2"),
        children:[
          {
            path: "",
            name: "",
            component: () => import("@components/Communityadmin"),
          },
          {
            path: "Communityadmin",
            name: "Communityadmin",
            component: () => import("@components/Communityadmin"),
          },
          {
            path: "searchCommunity",
            name: "searchCommunity",
            component: () => import("@components/searchCommunity"),
          },
        ]
      },
      {
        path: "My",
        name: "My",
        component: () => import("@components/My"),
      },
    ],
  },
  // 登录
  {
    path: "/login",
    name: "login",
    component: Login,
  },
   // 支付成功
   {
    path: "/paySuccess",
    name: "paySuccess",
    component: () => import("@views/paySuccess.vue"),
  },
   //查看支付状态
   {
    path: "/lookPayType",
    name: "lookPayType",
    component: () => import("@views/lookPayType.vue"),
  },
   // 支付失败
   {
    path: "/payError",
    name: "payError",
    component: () => import("@views/payError.vue"),
  },
  //会员
  {
    path : "/ServiceMallDef",
    name : "ServiceMallDef",
    component : () => import("@components/ServiceMallDef")
  },
  //会员静态页面
  {
    path : "/vipbuy",
    name : "VipBuy",
    component : () => import("@components/VipBuy")
  },
  //搜索页
  {
    path : "/search",
    name : "Search",
    component : () => import("@components/search")
  },
  //推荐页
  {
    path : "/recommend",
    name : "recommend",
    component : () => import("@views/recommend")
  },

  //分享
  {
    path : "/share",
    name : "share",
    component : () => import("@views/share")
  },

  // 信息库word详情
  {
    path: "/infodetail",
    name: "infodetail",
    component: () => import("@views/InfoDetail.vue"),
  },
  // 注册
  {
    path: "/register",
    name: "register",
    component: () => import("@views/Register.vue"),
  },
  // 投诉
  {
    path: "/complaint",
    name: "complaint",
    component: () => import("@views/complaint.vue"),
  },
  //忘记密码？
  {
    path : "/forgot",
    name : "forgot",
    component : () => import("@views/Forgot.vue")
  },
  {
    path: "/addposts",
    name: "/addposts",
    component: () => import("@views/AddPosts.vue"),
  },
  {
    path: "/addposts2/:type",
    name: "/addposts2",
    component: () => import("@views/AddPosts2.vue"),
  },
  // 关于
  {
    path: "/about",
    name: "/about",
    component: () => import("@views/About.vue"),
  },
  //更换密码
  {
    path : "/changepass",
    name : "changepass",
    component : () => import("@views/ChangePass.vue")
  },
  //网盘文件详情
  {
    path : "/fileword",
    name : "fileword",
    component : () => import("@components/common/FileWord.vue")
  },
  //详情页
  {
    path : "/details",
    name : "details",
    component : () => import("@views/details.vue")
  },
  //评论
  {
    path : "/comment",
    name : "comment",
    component : () => import("@views/comment.vue")
  },
  //立即购买
  {
    path : "/buynow",
    name : "buynow",
    component : () => import("@views/buynow.vue")
  },
   //更多
   {
    path : "/more",
    name : "more",
    component : () => import("@views/more.vue")
  },
  //个人
  {
    path : "/Userpersonal",
    name : "Userpersonal",
    component : () => import("@components/my/Userpersonal.vue")
  },

   //服务商城导航
   {
    path : "/ServiceMallNav",
    name : "ServiceMallNav",
    component : () => import("@views/ServiceMallNav.vue")
  },
  //银行卡
  // {
  //   path : "/bankCard",
  //   name : "bankCard",
  //   component : () => import("@views/bankcard.vue")
  // },
  //  //银行卡
  //  {
  //   path : "/addbankCard",
  //   name : "addBankCard",
  //   component : () => import("@views/addBankCard.vue")
  // },
  //网盘搜索结果
  {
    path : "/netfilesearch/:val",
    name : "netfilesearch",
    component : () => import("@components/common/NetFileSearch.vue")
  },
  
   //消息详情
   {
    path : "/CommunitDetails",
    name : "CommunitDetails",
    component : () => import("@views/CommunitDetails.vue")
  },

  //我的动态
  {
    path : "/dynamic",
    name : "dynamic",
    component : () => import("@components/my/dynamic.vue")
  },
  //我的订单
  {
    path : "/order/:tab",
    name : "order",
    component : () => import("@components/my/Order.vue")
  },
  //订单详情
  {
    path : "/orderitem",
    name : "orderitem",
    component : () => import("@components/my/OrderDetail.vue")
  },
  //订单评价
  {
    path : "/orderevaluate/:oid",
    name : "orderevaluate",
    component : () => import("@components/my/OrderEvaluate.vue")
  },
  //个人信息
  {
    path: "/personal",
    name: "personal",
    redirect: "/personal/personinfo",
    component: () => import("@views/personal-info/Personal.vue"),
    children: [
      {
        path: "personinfo",
        name: "personinfo",
        component: () => import("@views/personal-info/PersonInfo.vue"),
      },
      {
        path: "modifynickname",
        name: "modifynickname",
        component: () => import("@views/personal-info/ModifyNickName.vue"),
      },
      {
        path : "modifyphone",
        name : "modifyphone",
        component : () => import("@views/personal-info/ModifyPhone.vue")
      },
      // { //个性签名
      //   path : "modifypersign",
      //   name : "modifypersign",
      //   component : () => import("@views/personal-info/ModifyPerSign.vue")
      // }
    ]
  }
];

const router = new VueRouter({
  routes,
  //解决滚动条跳转后没有还原的问题
  scrollBehavior(to, from, saveTop){
    if (saveTop) {
        return saveTop;
    } else {
        return {x: 0, y: 0}
    }
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
  
}

const whiteList = ['/vipbuy','/login',"/home/ServiceMall","/CommunitDetails","/register","/forgot","/recommend","/share"]

router.beforeEach((to,from,next) =>{
  const user = window.localStorage.getItem("userInfo");
   if(user){
    if(to.path == "/home/ServiceMall" || from.path == "/home/ServiceMall"){
        let users = navigator.userAgent;
        if(!/iphone|ipad/i.test(users)){
          next("/home/ServiceMall/ServiceMalladmin");
        }else{
          next("/ServiceMallDef");
        }
    }else{
      next()
    }

   }else{
      if(whiteList.indexOf(to.path)!== -1){
          if(to.path == "/home/ServiceMall" || from.path == "/home/ServiceMall"){
              let users = navigator.userAgent;
              if(!/iphone|ipad/i.test(users)){
                next("/home/ServiceMall/ServiceMalladmin");
              }else{
                next("/vipbuy");
              }
          }else{
            next()
          }
      }else{
          next("/login");
        // next()
      }
   }
})
export default router;
