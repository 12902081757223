<template>
  <div class="w-login">
    <nav class="w-login-nav">
      <div class="w-login-nav-arrow" @click="onBackClick"></div>
    </nav>
    <div class="w-login-logo"></div>
    <div class="w-login-content">
      <div class="w-login-content-wrap">
        <div class="w-login-content-wrap-choose">
          <p
            class="w-login-content-wrap-choose-name"
            :class="{
              'w-login-content-wrap-choose-active': chooseIndex === 1,
            }"
            @click="handleChoose(1)"
          >
            手机登录
          </p>
          <p
            class="w-login-content-wrap-choose-name"
            :class="{
              'w-login-content-wrap-choose-active': chooseIndex === 2,
            }"
            @click="handleChoose(2)"
          >
            短信登录
          </p>
        </div>
        <div class="w-login-content-wrap-form">
          <div class="userlogin">
            <div class="user-box">
              <div class="icon"></div>
              <label for="user"> +86 </label>
              <input
                id="user"
                type="text"
                name="accountnumber"
                v-model="accountnumber"
                required
                pattern="^1[3456789][0-9]{9}$"
                placeholder="请输入个人账户"
              />
            </div>
            <div class="user-box" v-if="chooseIndex === 1">
              <div class="icon suo"></div>
              <label for="pwd">密码</label>
              <input
                id="pwd"
                :type="passwordType"
                name="password"
                v-model="password"
                required
                placeholder="请输入登录密码"
              />
              <div @click="showHidePwd">
                <van-icon name="closed-eye" v-if="ishide" />
                <van-icon name="eye-o" v-else />
              </div>
            </div>
            <div class="user-box" v-else>
              <div class="icon qrcode"></div>
              <label for="pwd">验证码</label>
              <input
                id="pwd"
                type="text"
                name="code"
                v-model="code"
                required
                placeholder="请输入验证码"
              />
              <span @click="sendSMS" v-if="issend" :class="btncolor?'btncolor':''">获取验证码</span>
              <span v-else style="color: #999999">重新获取{{ seedms }}</span>
            </div>
          </div>
          <div class="detial">
            <div class="autologin"  @click="loginChecked">
              <img src="~@image/login_check_blue.png" alt=""  v-if="checked">
              <img src="~@image/login_check.png" alt=""   v-else>
              
              <label for="autologin">一周内自动登录</label>
            </div>
            <div class="otherlogin">
              <div class="toregister"  @click="toRegister">
                <!-- <router-link to="register">立即注册</router-link> -->
                <div>立即注册</div>
              </div>
              <div class="forgot" @click="toForgot">
                <!-- <router-link to="forgot">忘记密码？</router-link> -->
                <div >忘记密码？</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="w-login-content-btn" @click="handleLogin">登录</button>
    </div>
    <div class="w-login-agreement">
      <span class="txt">
        <a href="javascript:void(0)" @click.stop="getUserAgree">《用户协议》</a
        >|
        <a href="javascript:void(0)" @click.stop="getPrivacyPolicy"
          >《隐私政策》</a
        >
      </span>
    </div>

    <van-popup v-model="sendVerif">
      <div class="over">
        <div class="content">
          <p class="send" v-if="chooseIndex == 2 && !logging">
            我们将发送验证码短信到<br />+86&nbsp;
            <span class="phoneNumber">{{ accountnumber }}</span>
          </p>
          <p v-show="chooseIndex == 1">{{ loginError }}</p>
          <p v-if="chooseIndex == 2 && logging">{{ loginError }}</p>
        </div>
        <div class="btn-group">
          <span class="cancel" @click="cancel">取消</span>
          <span class="sure" @click="sure">确定</span>
        </div>
      </div>
    </van-popup>
    
    <div class="w-login-footer"></div>
  </div>
</template>

<script>
import qs from "qs";
import { pwdLogin, getcode, codeLogin } from "@api/user.js";
export default {
  name: "WLogin",
  data() {
    return {
      // 账号
      accountnumber: "",
      // 密码
      password: "",
      // 登录方式切换
      chooseIndex: 1,
      // 验证码
      code: "",
      // 发送验证码倒计时
      seedms: 60,
      // 是否发送验证码
      issend: true,
      // 校验验证码登录的id
      codeId: "",
      // 控制显示隐藏密码
      ishide: true,
      // 控制密码输入的类型
      passwordType: "password",
      // 是否显示弹层
      sendVerif: false,
      // 登录错误
      loginError: "",
      // 是否要登录
      logging: false,
      infoType:'',
      btncolor:true,
      checked:false,  //控制多选
    };
  },
  destroyed(){
    sessionStorage.clear()
  },
  watch:{
    accountnumber:{
          handler:function(cur,old){
            if(cur != ""){
              console.log(cur)
              this.btncolor = false
            }else{
              this.btncolor = true
            }
          },
          deep:true,	//对象内部的属性监听，也叫深度监听
    }
  },
  methods: {
    loginChecked(){
       this.checked = !this.checked
    },
    showHidePwd() {
      this.ishide = !this.ishide;
      this.passwordType = this.ishide ? "password" : "text";
    },
    //注册
    toRegister(){
       this.$router.push({
         name:'register'
       })
    },
    //忘记密码
    toForgot(){
       this.$router.push({
         name:'forgot'
       })
    },
    // 登录
    async handleLogin() {
      if (!/^1[3|4|5|6|7|8]\d{9}$/.test(this.accountnumber)) {
        this.$toast("手机号码输入有误");
        return;
      }
      if (this.chooseIndex == 2 && !this.code) {
        this.$toast("请输入验证码");
        return;
      }
      // 登录方式 手机账号
      if (this.chooseIndex === 1) {
        // 登录参数
        const requestparam = {
          password: this.password,
          mobile: this.accountnumber,
        };
        //   发送请求
        const result = await pwdLogin(qs.stringify(requestparam));
        //   登录成功的处理
        this.validateLogin(result);
        // 验证码登录
      } else if (this.chooseIndex === 2) {
        // 参数
        const codeParam = {
          codeId: this.codeId,
          mobile: this.accountnumber,
          code: this.code,
        };
        const result = await codeLogin(qs.stringify(codeParam));
        this.validateLogin(result);
      }
      this.sendVerif = true;
      this.logging = true;
    },
    // 切换登录方式
    handleChoose(v) {
      this.chooseIndex = v;
    },
    // 获取验证码
    sendSMS() {
      if(this.btncolor == true){
        return
      }
      if (!/^1[3|4|5|7|8|9]\d{9}$/.test(this.accountnumber)) {
        this.$toast("请填写手机号");
      } else {
        this.sendVerif = true;
        this.logging = false;
      }
    },
    //确认发送验证码
    async sure() {
      if (this.chooseIndex == 1) {
        this.sendVerif = false;
        return;
      }
      if (this.chooseIndex == 2 && this.logging) {
        this.sendVerif = false;
        return;
      }
      const data = {
        mobile: this.accountnumber,
        type: 5,
      };
      const result = await getcode(qs.stringify(data));
      if (result.code === 0) {
        this.getTime();
        this.codeId = result.data.codeId;
      } else {
        this.$toast(result.msg);
      }
      this.sendVerif = false;
    },
    // 倒计时
    getTime() {
      let num = setInterval(() => {
        this.issend = false;
        this.seedms -= 1;
        if (this.seedms < 1) {
          clearInterval(num);
          if (this.seedms < 1) {
            this.issend = true;
            this.seedms = 60;
          }
        }
      }, 1000);
    },
    // 返回上一个历史记录
    onBackClick() {
      if (window.android){
        const jsonStr = {
          action : "back"
        }
        window.android.postMessage(JSON.stringify(jsonStr));
      }else if(window.webkit){
        const jsonStr = {
          action : "back"
        }
        window.webkit.messageHandlers.sendMessage.postMessage(JSON.stringify(jsonStr));
      }
    },
    validateLogin(result) {
      if (result.code === 0) {
        this.$toast("登录成功！");
        // 保存用户token
        console.log(result.data)
        this.$store.commit("Login_succ", result.data);
        // 获取用户的信息
        this.$store.dispatch("GET_PROFILE");
        // 需要判断当前的登录设备是安卓还是ios
        //
        if (window.android) {
          // 安卓
          //   window.android.postMessage();
          //   安卓通信 传递一个方法
          const data = {
            action: "login",
            data: result.data,
          };
          let jsonStr = JSON.stringify(data);
          window.android.postMessage(jsonStr);
        } else if (window.webkit) {
          // ios 环境下
          const data = {
            action: "login",
            data: result.data,
          };
          let jsonStr = JSON.stringify(data);

          window.webkit.messageHandlers.sendMessage.postMessage(jsonStr);

          console.log("isiOS");
        } else {
          console.log("isipc");
        }
        this.infoType = sessionStorage.getItem("InfoType")
        console.log(this.infoType)
        if(this.infoType == 2){
          this.$router.push({
            name:"recommend"
          })
        }else{
          this.$router.replace("/");
        }
      } else {
        //   失败处理
        this.loginError = result.msg;
      }
    },
    //取消弹层
    cancel() {
      this.sendVerif = false;
    },
    //获取用户协议
    getUserAgree() {
      if (window.android) {
        const data = { action: "UserAgreement" };
        let jsonStr = JSON.stringify(data);
        window.android.postMessage(jsonStr);
      } else if (window.webkit) {
        const data = { action: "UserAgreement" };
        let jsonStr = JSON.stringify(data);
        window.webkit.messageHandlers.sendMessage.postMessage(jsonStr);
      } else {
        console.log("isipc");
      }
    },
    //获取隐私政策
    getPrivacyPolicy() {
      if (window.android) {
        const data = { action: "PrivacyPolicy" };
        let jsonStr = JSON.stringify(data);
        window.android.postMessage(jsonStr);
      } else if (window.webkit) {
        const data = { action: "PrivacyPolicy" };
        let jsonStr = JSON.stringify(data);
        window.webkit.messageHandlers.sendMessage.postMessage(jsonStr);
      } else {
        console.log("isipc");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//弹层
::v-deep .van-popup {
  border-radius: 10px;
}
.over {
  width: 285px;
  height: 169px;

  .content {
    height: 115px;
    padding: 50px 60px 0;
    border-bottom: 1px solid #eee;

    p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 0 45px;

    span {
      font-size: 15px;
      font-weight: 500;
      color: #999;
    }

    .sure {
      color: #4588ff;
    }
  }
}
// 登录页面
.w-login {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  // 登录导航
  &-nav {
    width: 100%;
    height: 30px;
    // background-color: #fdfd55;
    display: flex;
    align-items: center;
    &-arrow {
      margin-left: 30px;
      width: 8px;
      height: 14px;
      background-image: url("~@image/arrowdown2.png");
      background-size: 8px 14px;
      background-repeat: no-repeat;
    }
  }
  // 登录的logo
  &-logo {
    margin: 0 auto;
    margin-top: 15px;
    width: 189px;
    height: 51px;
    background-image: url("~@image/logo.png");
    background-size: 189px 51px;
    background-repeat: no-repeat;
  }
  // 主体部分 登录表单
  &-content {
    position: relative;
    margin-top: 11px;
    width: 100%;
    height: 326px;
    background-image: url("~@image/loginlay.png");
    background-size: 100% 326px;
    background-repeat: no-repeat;
    &-wrap {
      font-size: 14px;
      color: #999999;
      // 切换登录方式
      &-choose {
        position: absolute;
        top: 59px;
        width: 100%;
        display: flex;
        justify-content: center;
        &-name {
          position: relative;
        }

        &-name + p {
          margin-left: 54px;
        }
        &-active {
          font-weight: bold;
          color: #333333;
        }
        &-active::before {
          display: block;
          position: absolute;
          bottom: -50%;
          left: 50%;
          transform: translateX(-50%);
          content: "";
          width: 10px;
          height: 3px;
          background-color: #4588ff;
        }
      }
      // 登录的表单相关样式
      &-form {
        width: 292px;
        min-width:292px;
        position: absolute;
        top: 99px;
        left: 50%;
        transform: translateX(-50%);
        .userlogin {
          .user-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width:calc(100% -40px);
            height: 54px;
            padding: 20px 0px;
            margin:0 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            // opacity: 0.2;
            .icon {
              flex-shrink: 0;
              width: 10px;
              height: 15px;
              margin-right: 8px;
              background-image: url("~@image/userphone1.png");
              background-size: 100% 100%;
            }
            .btncolor{
              color: #999999 !important;
            }
            label {
              flex-shrink: 0;
              width: 50px;
            }
            input {
              flex: 1;
              &:valid {
                color: #4587ff;
              }
            }
            #user{
              padding-left: 4px;
            }
            #pwd{
              width:80px;
              flex:1 0 80px;
            }
            .icon.suo {
              width:15px;
              height:15px;
              flex-shrink: 0;
              background-image: url("~@image/suo1.png");
              background-size:100%;
            }
            .icon.qrcode {
              width:11px;
              height:14px;
              margin-left:2px;
              flex-shrink: 0;
              background-image: url("~@image/qrcode1.png");
              background-size:100%;
            }
            span {
              color: #4588ff;
              flex-shrink: 0;
            }
          }
        }
        .detial {
          display: flex;
          margin-left: 21px;
          font-size: 12px;
          align-items: center;
          margin-top: 17px;
          height: 30px;
          .autologin {
            display: flex;
            align-items: center;
            img{
              width: 12.5px;
              height: 12.5px;
            }
            label {
              margin-left: 10px;
            }
          }
          .otherlogin {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            width: 140px;
            .forgot {
                z-index: 9999;
              div {
                color: #3783f3 !important;
              }
            }
            .toregister {
                 z-index: 9999;
              div {
                color: #3783f3 !important;
              }
            }
          }
        }
      }
    }
    &-btn {
      position: absolute;
      z-index:10;
      bottom: -47px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      letter-spacing: 2px;
      width: 295px;
      height: 49px;
      background: #4588FF;
      box-shadow: 0px 8px 8px 0px rgba(69, 136, 255, 0.33);
      border-radius: 49px;
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FEFEFE;
    }
    
  }
  //协议
  &-agreement {
    position: relative;
    width: 100%;
    top: 90px;
    text-align: center;
    .txt {
      font-size: 14px;
      color: #999;
      a {
        color: #3783f3;
      }
    }
  }
  // 底部样式
  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 82px;
    background-image: url("~@image/loginfooter.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

input[type="checkbox"] {
  width: 12px;
  height: 12px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 12px;
  position: relative;
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  border: 1px solid #cacdcf;
}

input[type="checkbox"]:checked::before {
  content: "\2713";
  background-color: #2196f3;
  color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  border: 1px solid #2196f3;
  font-size: 12px;
  font-weight: bold;
}
</style>
