import Vue from "vue";
import Vuex from "vuex";
import { getUserInfo } from "@api/user.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: null,
    profile: null,
    instance:''
  },
  mutations: {
    Login_succ(state, payload) {
      // 登录成功后的操作
      state.userInfo = payload;
      // 并且将用户信息存储到浏览器缓存中
      localStorage.setItem("userInfo", JSON.stringify(payload));
    },
    SAVE_PROFILE(state, payload) {
      state.profile = payload;
      localStorage.setItem("profile", JSON.stringify(payload));
    },
    instance_before(state,item){
      state.instance = item
    }
  },
  actions: {
    async GET_PROFILE({ commit }) {
      const result = await getUserInfo();
      if (result.code === 0) {
        commit("SAVE_PROFILE", result.data);
      }
    },
  },
});
