import axios from "axios";
import router from "../router";
import { Toast } from "vant";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
});
// s设置请求头
service.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
// request interceptor
service.interceptors.request.use(
  (config) => {
    //   请求需要携带用户的token

    let userInfo = window.localStorage.getItem("userInfo");
    if (!userInfo && window.android){
        userInfo = window.android.getUserToken();
    }
      config.headers["Authorization"] = userInfo
          ? JSON.parse(userInfo)["token"]
          : "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    //   401  没有权限 提示用户需要登录
    if (error && error.response.status === 401) {
      Toast({
        message: "登录过期，请重新登录！",
        position: "top",
      });
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default service;
