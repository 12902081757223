import request from "@utils/request";
// 账号密码登录
export function pwdLogin (data) {
  return request({
    url: "user/pwdLogin",
    method: "post",
    data,
  });
}

// 获取分享卡
export function shardGiftCard (data) {
  return request({
    url: "giftcard/shardGiftCard",
    method: "get",
    data,
  });
}

// 游客修改账号
export function updateUserMobile (data) {
  return request({
    url: "user/updateUserMobile",
    method: "post",
    data,
  });
}

// 获取验证码
export function getcode (data) {
  return request({
    url: "common/mobileAchCaptchas",
    method: "post",
    data,
  });
}

//取消订单
export function deleteOrderById (data) {
  return request({
    url: "pay/deleteOrderById",
    method: "post",
    data,
  });
}

//获取是否是最新版本
export function checkUserVersion (data) {
  return request({
    url: "user/checkUserVersion",
    method: "post",
    data,
  });
}
//查看支付状态
export function checkOrderPay (data) {
  return request({
    url: "pay/checkOrderPay",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    }
  });
}

//删帖
export function delArticle (data) {
  return request({
    url: "bbs/delArticle",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    }
  });
}

//删除bug
export function delBugOrSuggest (data) {
  return request({
    url: "bbs/delBugOrSuggest",
    method: "post",
    data,
  });
}

// 获取验证码
export function getInfo (data) {
  return request({
    url: "common/msgCount",
    method: "get",
    data,
  });
}

// 收藏列表
export function findAllCollectByUserId (data) {
  return request({
    url: "bbs/findAllCollectByUserId",
    method: "get",
    data,
  });
}

//根据用户id获取用户信息
export function getUserInfoByUserId (data) {
  return request({
    url: "/user/getUserInfoByUserId",
    method: "post",
    data,
  });
}
//用户关注
export function focusUser (data) {
  return request({
    url: "/user/focusUser",
    method: "post",
    data,
  });
}

//根据用户ID查询发布内容
export function findContentByUserId (data) {
  return request({
    url: "/bbs/findContentByUserId",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    }
  });
}

//修改手机号码
export function updatePhone (data) {
  return request({
    url: "/user/updateMobile",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    }
  });
}
// 验证码登录
export function codeLogin (data) {
  return request({
    url: "user/codeLogin",
    method: "post",
    data,
  });
}
// 用户注册
export function userRegister (data) {
  return request({
    url: "user/register",
    method: "post",
    data,
  });
}

// /user/getUserInfo

//获取用户信息
export function getUserInfo (data) {
  return request({
    url: "user/getUserInfoNew",
    method: "post",
    data,
  });
}
//修改用户信息
export function updateUserInfo (data) {
  return request({
    url: "/user/updateUserInfo",
    method: "post",
    data
  });
}

//我的动态
export function getdynamic (data) {
  return request({
    url: "/bbs/findMyContent",
    method: "post",
    data
  });
}

//我的点赞
export function getfabulous (data) {
  return request({
    url: "/bbs/findMyPraise",
    method: "post",
    data
  });
}

//退出登录
export function loginOut (data) {
  return request({
    url: "user/loginOut",
    method: "post",
    data,
  });
}
//修改密码
export function updatePwd (data) {
  return request({
    url: "/user/updatePwd",
    method: "post",
    data,
  });
}
//找回密码
export function forgotPwd (data) {
  return request({
    url: "/user/forgotPassword",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    }
  });
}

//修改头像
export function uploadHeadPortRait (data) {
  return request({
    url: "/user/uploadUserHeadImg",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

//获取全部订单信息
export function getOrderInfo (data) {
  return request({
    url: "/mall/findOrders",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json"
    }
  });
}

//评价上传图片
export function uploadImg (data) {
  return request({
    url: "/common/uploadImg",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

//新增评价
export function saveEvaluate (data) {
  return request({
    url: "/mall/saveEvaluate",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json"
    }
  });
}

