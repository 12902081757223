<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: "Write",
};
</script>

<style lang="scss">
body {
  overflow-anchor: none;
}
*{
-webkit-user-select: auto;
}
#app{
  font-family: PingFang SC;
}
</style>
